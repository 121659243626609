@import "colors.scss";

.viewResponseContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: $secondaryBackground;
  margin: 2rem;
  border-radius: 1rem;
  padding: 2rem;
  max-width: 45rem;
  margin: auto;
  .view-row {
    display: grid;
    @media only screen and (max-width: 600px) {
      grid-template-columns: 2fr 2fr;
    }
    grid-template-columns: 2fr 2fr 2fr;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    &-result {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
    .row-result-container {
      @media only screen and (max-width: 600px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 14px;
      }
    }
  }
  .results-row {
    display: flex;
    flex-direction: column;
  }
}
