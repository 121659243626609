@import "colors.scss";
.standings-table-container {
  .crownIcon {
    width: 30px;
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
  box-sizing: border-box;
  max-width: 800px;
  margin: auto;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: $secondaryBackground !important;
  //border-radius: 1rem !important;
  //margin: 0 2rem;
  position: relative;
  //width: calc(100% - 4rem) !important;
  table {
    padding: 2rem;
  }
}
