.fixtureSwingRow {
    display: flex;
    gap: 2.5rem;

    .fixCardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    }
}