@import "colors.scss";

body {
  background-color: $background;
  color: $text;
}

input {
  -webkit-text-fill-color: #cfcece !important;
}

.result-0 {
  color: $error;
}
.result-2 {
  color: $danger;
}
.result-3 {
  color: $success;
}

a:-webkit-any-link {
  color: #fff;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.MuiSelect-select {
  background-color: $input !important;
}
.MuiTablePagination-select[aria-expanded="true"] {
  border: 2px solid green;
}
.MuiInputLabel-formControl {
  color: $text !important;
}
.MuiMenu-list {
  background-color: $secondaryBackground;
}

.hero-container {
  height: 700px;
  position: absolute;
  width: 100%;
  img {
    height: 100%;
    position: relative;
    opacity: 0.2;
    z-index: -1;
    width: 100%;
    object-fit: cover;
  }
  .hero-container-gradient {
    height: 160px;
    width: 100%;
    position: absolute;
    top: 540px;
    background-color: #121212;
    background: linear-gradient(transparent, #121212);
  }
}

.countdown {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translateX(-50%);
}
