@import "colors.scss";

.container {
  margin-right: 2rem;
  margin-left: 2rem;
  max-width: 80rem;
  margin: auto;
  position: relative;
  margin-bottom: 3rem;
  color: $text;
}

.background {
  background-color: $background;
}

button {
  height: 3rem;
}

.drawer {
  > div {
    top: 57px;
    background-color: $purple;

    a {
      color: white;
    }
  }
}

.startpageCards {
  display: flex;
  justify-content: center;
  position: relative;
  top: 190px;
  gap: 20px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.mobile-only {
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}
