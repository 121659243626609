$purple: #38003c;
$background: #121212;
$grey: #cfcece;
$text: #cfcece;
$secondaryBackground: #232d3f;
$success: #22bb33;
$error: #bb2124;
$danger: #f0ad4e;
$input: #3e485a;
$brown: #463a3a;
