@import "colors.scss";

.submit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background-color: $secondaryBackground;
  margin: 2rem;
  border-radius: 1rem;
  padding: 2rem;
  max-width: 45rem;
  margin: auto;
  .submit-form-help {
    width: fit-content;
    position: relative;
    background-color: rgb(177, 177, 177);
    border-radius: 8px;
    padding: 1rem;
    div {
      color: #3a3b3c;
    }
  }
  .submit-form-matches {
    margin: 1rem 0;
  }
  .form-row {
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 1rem;
      span {
        text-align: left !important;
      }
    }
    padding: 1rem 0;
    border-bottom: 1px solid #384252;
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    &-result {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
