@import "colors.scss";

.prizesleft-container {
  padding: 1rem;
  background-color: $brown;
  color: white;
  position: absolute;
  top: 197px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-3.24deg);
  min-width: 205px;
  width: max-content;
  height: 110px;
  outline: 1px solid white;
  outline-offset: -7px;
  border-radius: 10px;
  box-sizing: border-box;
  &__triangle {
    position: absolute;
    margin: auto;
    top: -16px;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    transform: rotate(17deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }
  div {
    font-family: "LuckiestGuy";
  }
}
